import React, { useEffect, useContext } from 'react';

import Feedback from '../components/feedback';
import Card from '../components/card';
import Page from '../components/page';
import SEO from '../components/seo';

import { PageWrapperContext } from '../components/page-wrapper';
import { useData } from '../components/data';

import styles from '../styles/pages/chapters.module.scss';

const ChaptersPage = ( { transitionStatus } ) => {

    const pageWrapperContext = useContext( PageWrapperContext );

    const { chapters } = useData();

    useEffect( () => {
        pageWrapperContext.setPageClass( 'page-chapters' );
    }, [] );

    const renderChapters = () => {
        return chapters.map( ( chapter, index ) => {
            const { frontmatter, articles } = chapter;
            const { title, description, slug, iconSmallPath } = frontmatter;
            return <Card
                key={index}
                title={title}
                articleCount={articles.length}
                description={description}
                url={slug}
                buttonText="Go to chapter"
                imagePath={iconSmallPath}
            />;
        } );
    };

    return <Page transitionStatus={transitionStatus}>
        <SEO title="Chapters" description="This collections of case-studies, best practices and learnings provided by cross-border practitioners of Europe wants to help everyone who is interested in the cross-border method, who wants to start cross-border projects or wants to gain more cross-border skills." image={'/images/opengraph/general.jpg'} />
        <section className={'container-fluid'}>
            <div className={`row ${styles.title}`}>
                <div className="col col-12">
                    <h2>This collections of case-studies, best practices and learnings provided by cross-border practitioners of Europe wants to help everyone who is interested in the cross-border method, who wants to start cross-border projects or wants to gain more cross-border skills.</h2>
                </div>
            </div>
            <div className="row">
                {renderChapters()}
            </div>
        </section>
        <Feedback />
    </Page>;
};

export default ChaptersPage;
